<template>
  <section>
    <b-row>
      <b-col cols="12">
        Dashboard Home
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {},
};
</script>

<style lang="scss"></style>
